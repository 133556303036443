<template>

  <div class="relative">
             <header class="main-head">
               <div class="main-head">  
  <input type="checkbox" class="openSidebarMenu " id="openSidebarMenu">
  <label for="openSidebarMenu" class="sidebarIconToggle">
    <div class="spinner2 diagonal part-1"></div>
    <div class="spinner2 horizontal"></div>
    <div class="spinner2 diagonal part-2"></div>
  </label>
  <div id="sidebarMenu">
    <ul class="sidebarMenuInner">
      <li><router-link class="sublink" to="/" exact=""> Lista de casos</router-link></li>
      <li><router-link class="sublink" to="/nuevaCotizacion" exact="">  Cotización rápida</router-link></li>
      <li><router-link class="sublink" to="/nuevoCaso" exact=""> Nuevo caso</router-link></li>
      <li><router-link class="sublink" to="/reportesCredito" exact="">Reportes de Crédito</router-link></li>
      <li><router-link class="sublink" to="/PrecaSoc" exact=""></router-link></li>
       
        <logout />
    
     
    </ul>
  </div>
</div>
           </header>

    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <!-- <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" /> -->
        <!--<vx-tooltip :text="'Botón de inicio'" position="left">
        <feather-icon icon="HomeIcon" @click="$router.push('/').catch(() => {})" v-if="current"/>
        </vx-tooltip>
        <vs-spacer />-->
        <div :text="'Botón de inicio'" class="logo-position"  >
         <a class=" header-logo" @click="$router.push('/')">  <img src="../../../assets/images/pages/logo-soc-nuevo.png" alt="logo-soc" style="margin: .5rem; height: 3.6rem;"/></a>
        </div>
        <vs-spacer />

        <div class="perfil" :text="'Menú para el perfil'" position="bottom">
        <profile-drop-down />
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import Bookmarks            from "./components/Bookmarks.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationDropDown from "./components/NotificationDropDown.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"
import Logout from './components/Logout.vue'

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
    Logout,
  },
  data(){
		return{
      current:'/',
    }
  },
  // mounted(){
  //
  // },
  watch:{
    $route:function() {
      if(this.$router.currentRoute.path == '/'){
        this.current= false
      }else {
        this.current= true
      }
    }
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default")      return "navbar-default"
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
      else if (this.verticalNavMenuWidth == 'full')    return "navbar-full"
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  }
}
</script>
<style >

</style>
<style scoped media="screen">
.logo-position:hover{
    border:	#006D4E 2px solid;
    border-radius: 5px;    
}
</style>
