import { render, staticRenderFns } from "./TheNavbarVertical.vue?vue&type=template&id=44cab44c&scoped=true&"
import script from "./TheNavbarVertical.vue?vue&type=script&lang=js&"
export * from "./TheNavbarVertical.vue?vue&type=script&lang=js&"
import style1 from "./TheNavbarVertical.vue?vue&type=style&index=1&id=44cab44c&prod&scoped=true&media=screen&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44cab44c",
  null
  
)

export default component.exports