export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    url: "/caso",
    name: "Caso",
    slug: "page2",
    icon: "FileIcon",
  },
]
