<template>
  <div v-if="activeUserInfo.Nombre">   
          <div
            id="logout"
            class="logout"
            @click="logout"
            style="cursor: pointer;"
            >  
           Cerrar sesión
          </div>
  </div>
</template>

<script>

export default {
  name:"logout",
  data() {
    return {
      soc:false,
      inmobiliaria:false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    socData(){
      return this.$store.state.socData
    }
  },
  mounted(){
    if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){
      this.inmobiliaria=true
    }else{
      this.soc = true
    }
  },
  methods: {
    logout() {

      if((JSON.parse(localStorage.getItem('userInfo')).IdToken!='' || JSON.parse(localStorage.getItem('userInfo')).IdToken !=null) && JSON.parse(localStorage.getItem('userInfo')).SesionMultilogin==true){
          this.ActualizaSesionMultilogin();
         
         /* this.$cookie.delete('key');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('socData');*/
          //this.$router.push('/login').catch(() => {})
         

         
      }else{
        this.$cookie.delete('key');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('socData');
        this.$router.push('/login').catch(() => {})
      }

        
    },
     ActualizaSesionMultilogin(){
      let self=this
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'CerrarSesionMultilogin',
        objEjecutivo: {
        EMail: JSON.parse(localStorage.getItem('userInfo')).EMail
        
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

              this.$cookie.delete('key');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('socData');

              if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){

                    ///var WinPrint =  window.open('https://authext.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/');
                    ////this.$router.push('/login').catch(() => {})
                    
                window.location = 'https://authext.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https%3a%2f%2fcotizador.socasesores.com%2fapp%2flogin%2f'+'&redirect_uri=https%3a%2f%2fcotizador.socasesores.com%2fapp%2flogin%2f'


              }else{
                   //// var WinPrint =  window.open('https://auth.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/');
                   //// this.$router.push('/login').catch(() => {})

                 window.location = 'https://auth.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https%3a%2f%2fcotizador.socasesores.com%2fapp%2flogin%2f'+'&redirect_uri=https%3a%2f%2fcotizador.socasesores.com%2fapp%2flogin%2f'

              }

             
            

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Cerrar Sesion Multilogin',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

  }



}
</script>
